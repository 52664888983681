import React from "react"

import Navbar from "../components/Navbar"
import Footer from "../components/Footer"
import SEO from "../components/SEO"

import FAQPage from "../components/FAQPage"

const FAQ = () => {
  return (
    <>
      <SEO title="FAQ" />
      <Navbar back bottomOffset={125} />
      <FAQPage />
      <Footer back />
    </>
  )
}

export default FAQ
