import React from "react"
import styled from "styled-components"

const Container = styled.div`
  transform: rotate(-1deg);
  svg {
    enable-background: new 0 0 2449.8 441.8;
  }
`

const Heading = () => {
  return (
    <Container>
      <svg id="Layer_1" x="0px" y="0px" viewBox="0 0 2449.8 441.8">
        <g>
          <path d="M64.2,48.6c0,0.1,20,0.1,60,0v23.3c-40,0.2-60,0.2-60,0v45.8H0V3.8h124.2v29.3c-40-0.3-60-0.3-60,0V48.6z" />
          <path
            d="M198.3,77.3h-2.7v40.4h-64.3V3.8H254c26.9,0,41.8,13.2,41.8,36.8c0,18.2-8.1,29.6-24.4,34.4l33.1,42.7h-78.3L198.3,77.3z
		 M230.1,40c0-9.2-7.2-14.7-15.5-14.7h-19.1v29.3h19.1C223.7,54.6,230.1,49.5,230.1,40z"
          />
          <path
            d="M371.9,48.6c0,0.1,20.6,0.1,61.8,0v23.3c-41.2,0.2-61.8,0.2-61.8,0v16.5h61.8v29.3h-126V3.8h126v29.3
		c-41.2-0.3-61.8-0.3-61.8,0V48.6z"
          />
          <path
            d="M584.1,115.8l18.6,11.7L576,147.9l-46.2-27.7h-1.2c-21.6,0-37.3-0.9-47.1-2.6c-15.8-2.9-26.3-9.6-31.3-20.4
		c-3.2-7.1-4.7-19.4-4.7-37.1s1.5-30.1,4.7-37.1c5-10.8,15.5-17.6,31.3-20.4C491.4,0.9,507,0,528.7,0c21.5,0,37.1,0.9,46.9,2.6
		c15.8,2.9,26.3,9.6,31.3,20.4c3.2,7.1,4.7,19.4,4.7,37.1c0,16.1-1.2,27.5-3.6,34.3C604.1,104.8,596.2,112,584.1,115.8z M544.1,90.5
		c2.1-5,3.2-15.2,3.2-30.7c0-16.7-1.1-27.1-3.2-31.3c-2.6-5.1-7.8-7.7-15.5-7.7c-7.7,0-12.8,2.6-15.5,7.7
		c-2.1,4.1-3.2,14.4-3.2,31.3c0,15.6,1.1,25.9,3.2,30.7c1.7,3.8,4.7,6.5,9,8.1l14.9-12.5L544.1,90.5z"
          />
          <path
            d="M686,3.8v56.1c0,19.1,0.3,29.3,0.9,30.7c2.6,6,7.5,9,15,9c7.7,0,12.6-3,15.2-9c0.8-1.7,1.1-11.9,1.1-30.7V3.8H783v56.4
		c0,17.9-1.5,30.2-4.5,37.1c-4.8,10.8-15,17.6-30.5,20.4c-9.5,1.7-24.8,2.6-46,2.6c-21.2,0-36.5-0.9-46-2.6
		c-15.5-2.9-25.7-9.6-30.5-20.4c-3-6.9-4.5-19.2-4.5-37.1V3.8H686z"
          />
          <path
            d="M855.9,48.6c0,0.1,20.6,0.1,61.8,0v23.3c-41.2,0.2-61.8,0.2-61.8,0v16.5h61.8v29.3h-126V3.8h126v29.3
		c-41.2-0.3-61.8-0.3-61.8,0V48.6z"
          />
          <path d="M1028.1,47.4V3.8h61v113.9h-62.7l-37.3-48.9v48.9h-61V3.8h64L1028.1,47.4z" />
          <path d="M1143.1,33.1h-42.8V3.8h149.4v29.3h-42.2v84.6h-64.3V33.1z" />
          <path d="M1319.9,3.8v84.6h57v29.3h-121.2V3.8H1319.9z" />
          <path d="M1445.6,77.1l-60.4-73.4h77.4l15.2,23.6l15-23.6h77.4l-60.4,73.4v40.6h-64.2V77.1z" />
          <path
            d="M1682.9,102.1l-5.4,15.6h-62.8l40.4-113.9h100.6l40.3,113.9h-62.7l-5.6-15.6H1682.9z M1718.8,76.8l-13.5-38.5l-13.5,38.5
		H1718.8z"
          />
          <path
            d="M1842.2,78.8c-14.4,0-24.8-2.7-31.3-8c-7.1-5.9-10.5-15.9-10.5-30.2c0-19.8,8.7-32.2,26.3-36.8c8.6-2.3,25.9-3.6,51.9-3.8
		c8.9,0,16.8,0.2,23.9,0.5c17,0.6,28.4,1.7,34.1,3.3c6.5,1.8,11.3,4.8,14.1,9c3.2,4.5,5.1,11.4,5.7,20.9h-63.6
		c0-7.2-5.1-12.2-13.5-12.2c-9.5,0-14.1,3.5-14.1,10.2c0,3,1.7,5.4,5,7.4c2.9,1.7,5.7,2.4,8.7,2.4h40.9c14.4,0,24.8,2.7,31.3,8.1
		c7.1,5.9,10.5,15.8,10.5,30.1c0,11.6-2.6,20.6-7.8,27.2c-4.7,5.9-10.8,9.5-18.5,10.8c-6.6,1.2-13.4,2-20.3,2.3
		c-5.1,0.2-15.9,0.3-32.5,0.3c-34.6,0-55.2-0.9-61.9-2.6c-12.6-3.2-17.7-11.7-18.9-31.1h66.4c0,7.2,5.3,12.2,13.7,12.2
		c9.5,0,14.1-3.5,14.1-10.2c0-3-2-5.4-5.9-7.4c-3.2-1.7-6.2-2.4-9.3-2.4H1842.2z"
          />
          <path d="M2030.1,72.8v44.9h-64.2V3.8h64.2v44.8l29.6-44.8h71.6l-36.1,51.9l45.8,62.1h-77.9L2030.1,72.8z" />
          <path
            d="M2213.9,48.6c0,0.1,20.6,0.1,61.8,0v23.3c-41.2,0.2-61.8,0.2-61.8,0v16.5h61.8v29.3h-126V3.8h126v29.3
		c-41.2-0.3-61.8-0.3-61.8,0V48.6z"
          />
          <path
            d="M2366.8,3.8c3.8,0,10.1-0.1,18.9-0.3c6.2-0.2,11,0,14.4,0.2c4.7,0.1,9.3,0.6,13.7,1.4c15.8,2.7,26.3,9.3,31.3,19.7
		c3.2,6.6,4.7,18.5,4.7,35.5s-1.5,28.9-4.7,35.5c-5,10.4-15.5,17-31.3,19.7c-9.5,1.7-25.3,2.4-47.1,2.4h-80V3.8H2366.8z
		 M2382.9,85.1c1.5-3.8,2.3-12.3,2.3-25.7c0-9.8-0.9-18.2-2.7-25c-0.6-2.3-2.3-4.4-4.8-6.2c-3-2-6.5-3-10.2-3H2351v71h16.4
		C2374.8,96.2,2380,92.5,2382.9,85.1z"
          />
          <path
            d="M245.4,384.9l32.9,20.7l-47.3,36.1l-81.6-48.9h-2.1c-38.3,0-65.9-1.6-83.1-4.5c-27.9-5-46.5-17-55.3-36.1
		c-5.6-12.5-8.2-34.3-8.2-65.6S3.4,233.5,9,221c8.8-19.1,27.4-31.1,55.3-36.1c17.3-2.9,44.9-4.5,83.1-4.5c38,0,65.6,1.6,82.9,4.5
		c27.9,5,46.5,17,55.3,36.1c5.6,12.5,8.2,34.3,8.2,65.6c0,28.4-2.1,48.6-6.4,60.6C280.7,365.5,266.7,378.3,245.4,384.9z
		 M174.8,340.3c3.7-8.8,5.6-26.8,5.6-54.2c0-29.5-1.9-47.8-5.6-55.3c-4.5-9-13.8-13.5-27.4-13.5c-13.5,0-22.6,4.5-27.4,13.5
		c-3.7,7.2-5.6,25.5-5.6,55.3c0,27.6,1.9,45.7,5.6,54.2c2.9,6.6,8.2,11.4,15.9,14.3l26.3-22L174.8,340.3z"
          />
          <path
            d="M425.5,187v99.1c0,33.7,0.5,51.8,1.6,54.2c4.5,10.6,13.3,15.9,26.6,15.9c13.5,0,22.3-5.3,26.8-15.9
		c1.3-2.9,1.9-21,1.9-54.2V187h114.5v99.6c0,31.6-2.7,53.4-8,65.6c-8.5,19.1-26.6,31.1-53.9,36.1c-16.7,2.9-43.8,4.5-81.3,4.5
		s-64.6-1.6-81.3-4.5c-27.4-5-45.4-17-53.9-36.1c-5.3-12.2-8-34-8-65.6V187H425.5z"
          />
          <path
            d="M725.6,266.2c0,0.3,36.4,0.3,109.2,0v41.2c-72.8,0.3-109.2,0.3-109.2,0v29.2h109.2v51.8H612.2V187h222.6v51.8
		c-72.8-0.5-109.2-0.5-109.2,0V266.2z"
          />
          <path
            d="M926.7,319.6c-25.5,0-43.8-4.8-55.3-14.1c-12.5-10.4-18.6-28.2-18.6-53.4c0-35.1,15.4-56.8,46.5-65.1
		c15.1-4,45.7-6.4,91.6-6.6c15.7,0,29.8,0.3,42.2,0.8c30,1.1,50.2,2.9,60.3,5.8c11.4,3.2,19.9,8.5,25,15.9c5.6,8,9,20.2,10.1,36.9
		h-112.4c0-12.8-9-21.5-23.9-21.5c-16.7,0-25,6.1-25,18.1c0,5.3,2.9,9.6,8.8,13c5,2.9,10.1,4.3,15.4,4.3h72.3
		c25.5,0,43.8,4.8,55.3,14.3c12.5,10.4,18.6,27.9,18.6,53.1c0,20.5-4.5,36.4-13.8,48.1c-8.2,10.4-19.1,16.7-32.7,19.1
		c-11.7,2.1-23.6,3.5-35.9,4c-9,0.3-28.2,0.5-57.4,0.5c-61.1,0-97.5-1.6-109.4-4.5c-22.3-5.6-31.3-20.7-33.5-55h117.4
		c0,12.8,9.3,21.5,24.2,21.5c16.7,0,25-6.1,25-18.1c0-5.3-3.5-9.6-10.4-13c-5.6-2.9-10.9-4.3-16.5-4.3H926.7z"
          />
          <path d="M1220,238.8h-75.7V187h264.1v51.8h-74.6v149.6H1220V238.8z" />
          <path d="M1420,388.4V187h113.4v201.4H1420z" />
          <path
            d="M1697.8,180.4c38,0,65.6,1.6,82.9,4.5c27.9,5,46.5,17,55.3,36.1c5.6,12.5,8.2,34.3,8.2,65.6s-2.7,53.1-8.2,65.6
		c-8.8,19.1-27.4,31.1-55.3,36.1c-17.3,2.9-44.9,4.5-82.9,4.5c-38.3,0-65.9-1.6-83.1-4.5c-27.9-5-46.5-17-55.3-36.1
		c-5.6-12.5-8.2-34.3-8.2-65.6s2.7-53.1,8.2-65.6c8.8-19.1,27.4-31.1,55.3-36.1C1631.9,182,1659.6,180.4,1697.8,180.4z
		 M1697.8,356.2c13.5,0,22.6-5.3,27.4-15.9c4-8.8,5.8-26.8,5.8-54.2c0-29-1.9-47.5-5.8-55.3c-4.5-9-13.8-13.5-27.4-13.5
		c-13.5,0-22.6,4.5-27.4,13.5c-3.7,7.2-5.6,25.5-5.6,55.3c0,27.9,1.9,46,5.6,54.2C1675.2,350.9,1684.3,356.2,1697.8,356.2z"
          />
          <path d="M2038.1,264.1v-77h107.9v201.4h-110.8l-65.9-86.3v86.3h-107.9V187h113.2L2038.1,264.1z" />
          <path
            d="M2238.9,319.6c-25.5,0-43.8-4.8-55.3-14.1c-12.5-10.4-18.6-28.2-18.6-53.4c0-35.1,15.4-56.8,46.5-65.1
		c15.1-4,45.7-6.4,91.6-6.6c15.7,0,29.8,0.3,42.2,0.8c30,1.1,50.2,2.9,60.3,5.8c11.4,3.2,19.9,8.5,25,15.9c5.6,8,9,20.2,10.1,36.9
		h-112.4c0-12.8-9-21.5-23.9-21.5c-16.7,0-25,6.1-25,18.1c0,5.3,2.9,9.6,8.8,13c5,2.9,10.1,4.3,15.4,4.3h72.3
		c25.5,0,43.8,4.8,55.3,14.3c12.5,10.4,18.6,27.9,18.6,53.1c0,20.5-4.5,36.4-13.8,48.1c-8.2,10.4-19.1,16.7-32.7,19.1
		c-11.7,2.1-23.6,3.5-35.9,4c-9,0.3-28.2,0.5-57.4,0.5c-61.1,0-97.5-1.6-109.4-4.5c-22.3-5.6-31.3-20.7-33.5-55h117.4
		c0,12.8,9.3,21.5,24.2,21.5c16.7,0,25-6.1,25-18.1c0-5.3-3.5-9.6-10.4-13c-5.6-2.9-10.9-4.3-16.5-4.3H2238.9z"
          />
        </g>
      </svg>
    </Container>
  )
}

export default Heading
