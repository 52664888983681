import React from "react"
import styled from "styled-components"

import Heading from "./heading"

import media from "../../styles/media"

const Container = styled.div`
  background-color: rgba(0, 93, 107, 0.99);
  display: grid;
  grid-template: auto auto / 1fr;
  ${media.tablet``}
`

const HeadingContainer = styled.div`
  position: relative;
  overflow: hidden;
`

const Body = styled.div`
  padding: 15vh 15vw 15vh 10vw;
  h4 {
    font-size: 1.25rem;
    margin: 2rem 0;
    line-height: 1.25;
    font-family: "butlermedium";
  }
  p {
    font-size: 1rem;
    a {
      text-decoration: underline;
    }
  }
  ${media.tablet`
    padding: 15vh 25vw 15vh 20vw;
    h4 {
      font-size: 1.85rem;
    }
    p {
      font-size: 1.25rem;
      line-height: 1.75;
    }
  `}
`

const FAQPage = () => {
  return (
    <Container>
      <HeadingContainer>
        <Heading />
      </HeadingContainer>
      <Body>
        <h4>What is Sound Idea Sessions?</h4>
        {/* <p>
          Sound Idea Sessions is not another business conference. For the
          inaugural Sound Idea Sessions, we are presenting you with a 5-hour
          Masterclass, hosted by bestselling author, Ryan Holiday, who will be
          giving you the inside scoop on new marketing tactics. After a glimpse
          into the strategies of top start-ups and tech unicorns, quintessential
          South African Growth Hacker, DJ Black Coffee will join Ryan on stage
          to discuss Conquering International Markets, and what he and his team
          are doing to propel his brand to the global market. The two
          heavyweights will all be taking questions from the audience. Join us
          on 7 November at 1 Fox Junction in Johannesburg to learn, direct from
          Ryan and Black Coffee, on how to create smart, savvy and provocative
          PR campaigns.{" "}
          <a href="https://www.soundideasessions.com/#about">Read more</a>
        </p> */}
        <p>
          Sound Idea Sessions is not another business conference. For the
          inaugural Sound Idea Sessions, we are presenting you with a 5-hour
          Masterclass, hosted by bestselling author, Ryan Holiday, who will be
          giving you the inside scoop on new marketing tactics. After a glimpse
          into the strategies of top start-ups and tech unicorns, quintessential
          South African Growth Hacker, DJ Black Coffee will join Ryan on stage
          to discuss Conquering International Markets, and what he and his team
          are doing to propel his brand to the global market. The two
          heavyweights will all be taking questions from the audience.
          <a href="https://www.soundideasessions.com/#about">Read more</a>
        </p>

        <h4>Where does Sound Idea Sessions take place?</h4>
        <p>
          Sound Idea Sessions takes place at the{" "}
          <a href="https://www.soundideasessions.com/#venue">venue</a> in
          Newtown, Johannesburg.
        </p>

        <h4>What does my ticket entitle me too?</h4>
        <p>
          Please check out our{" "}
          <a href="https://www.soundideasessions.com/#tickets">tickets</a> for
          more information on our ticket options.
        </p>

        <h4>Where can I find the agenda?</h4>
        <p>
          Click <a href="https://www.soundideasessions.com/#agenda">here</a> can
          find the Sound Idea Sessions agenda.
        </p>

        <h4>Where can I stay? </h4>
        <p>
          <a href="http://thefranklin.co.za">The Franklin</a> (750m from Fox
          Junction)
        </p>
        <p>
          <a href="https://faircity.co.za/faircity-hotels/mapungubwe-faircity-hotel-johannesburg/">
            Mapungubwe Hotel
          </a>{" "}
          (1.2km from Fox Junction)
        </p>
        <p>
          <a href="https://tinyurl.com/y5metb5f">Protea Hotel Parktonian</a>{" "}
          (2.3 km from Fox Junction)
        </p>

        <h4>Is there parking at the venue?</h4>
        <p>Yes, free parking is provided at the venue.</p>

        <h4>
          I am interested in sponsoring Sound Idea Sessions, who do I contact?
        </h4>
        <p>
          To find out more information on becoming a Sound Idea Sessions
          sponsor, please email us at{" "}
          <a
            href="mailto:sponsor@soundideasessions.com?subject=SIS%20Sponsorship%20Inquiry"
            target="_top"
          >
            here
          </a>
        </p>

        <h4>I am in the media, who do I contact?</h4>
        <p>
          For media inquiries, please mail us{" "}
          <a
            href="mailto:media@soundideasessions.com?subject=Media%20Inquiry"
            target="_top"
          >
            here
          </a>
        </p>
      </Body>
    </Container>
  )
}

export default FAQPage
